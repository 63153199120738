<template>
  <div class="card">
    <div class="bg-blue-light p-1">
      <TitleButton
          btnTitle="List"
          :showBtn="true"
          :showAddNew="false"
          title="Add New Warehouse"
          @onClickAddNewButton="navigateToListPage"
      />

      <div class="row mt-2 gy-1">
        <div class="row mt-1 justify-content-center">
          <div class="col-sm-2">
            <label class="align-middle">Warehouse Type</label>
          </div>
          <div class="col-sm-6">
            <v-select
                placeholder="Select Warehouse type"
                v-model="formData.type_id"
                :options="warehouseTypes"
                label="type"
                :reduce="type => type.id"
            />
          </div>
        </div>
        <div class="row mt-1 justify-content-center">
          <div class="col-sm-2">
            <label class="align-middle">Warehouse Name</label>
          </div>
          <div class="col-sm-6">
            <input
                v-model="formData.name"
                type="text"
                class="form-control text-right"
            >
          </div>
        </div>
        <div class="row mt-1 justify-content-center">
          <div class="col-sm-2">
            <label class="align-middle">Warehouse Code</label>
          </div>
          <div class="col-sm-6">
            <input
                v-model="formData.code"
                type="text"
                class="form-control text-right"
            >
          </div>
        </div>
      </div>

      <div class="pb-5 px-2 mt-4">
        <div class="d-flex flex-wrap gap-1 gy-2 justify-content-center">
          <button :disabled="saveButtonLoader || saveNewButtonLoader" @click="handleSubmit(true)"
                  class="btn btn-primary">
            Save
          </button>
          <button :disabled="saveButtonLoader || saveNewButtonLoader" @click="handleSubmit(false)"
                  class="btn btn-primary">Save & New
          </button>
          <button @click="navigateToListPage" class="btn btn-outline-secondary">Cancel</button>
        </div>
      </div>
    </div>
    <GlobalLoader/>
  </div>
</template>

<script setup>
import {inject, ref} from 'vue'
import {useRoute, useRouter} from 'vue-router'
import TitleButton from '@/components/atom/TitleButton'

const router = useRouter()
const route = useRoute()

const showError = inject('showError');
const showSuccess = inject('showSuccess')

let saveButtonLoader = ref(false)
let saveNewButtonLoader = ref(false)
let warehouseTypes = ref([])
let formData = ref({
  type_id: null,
  name: null,
  code: null
})

function navigateToListPage() {
  router.push({name: 'warehouse-list', params: route.params, query: route.query})
}

function handleSubmit(redirect = false) {
  if (redirect) {
    saveButtonLoader.value = true
  } else {
    saveNewButtonLoader.value = true
  }

  // TODO
}
</script>